import React from "react"
import { Link } from "gatsby"
import logoDark from "../images/logo-dark.png"

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-md-4">
            <div className="logo">
              <Link to="/"><img src={logoDark} alt="Crafters Software Studio" /></Link>
            </div>
          </div>
          <div className="col-xs-12 col-md-2">
            <nav>
              <li><Link to="/">Home</Link></li>
              <li><Link to="/bootcamp">Training</Link></li>
              {/* <li><Link to="/blog">Blog</Link></li> */}
              <li><Link to="/contact">Contact</Link></li>
            </nav>
          </div>
          <div className="col-xs-12 col-md-2">
            <nav>

            </nav>
          </div>
          <div className="col-xs-12 col-md-3 text-right">
            <ul className="social-media">
              <li><a href="https://www.facebook.com/craftersinnovationstudio" target="_blank" rel="noopener noreferrer"><span className="fab fa-facebook-f"></span></a></li>
              <li><a href="https://twitter.com/Crafterstudio" target="_blank" rel="noopener noreferrer"><span className="fab fa-twitter"></span></a></li>
              <li><a href="https://www.youtube.com/channel/UCb8UjTMq_f6ITFwGsWXixLw" target="_blank" rel="noopener noreferrer"><span className="fab fa-youtube"></span></a></li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;