import { Link } from "gatsby"
import React from "react"
import { Logo } from "./image"

const Header = ({ siteTitle }) => (
  <header>
    <nav className="navbar">
      <div className="container">
        <div className="navbar-header">
          <button type="button" className="navbar-toggle collapsed" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
            <span className="sr-only">Toggle navigation</span>
            <span className="fa fa-bars"></span>
          </button>
          <Link to="/" className="navbar-brand">
            <Logo className="navbar-brand" />
          </Link>
          {/* <a className="navbar-brand" href="/"><img src="/img/logo.png" alt="Crafters Innovation Studio" /></a> */}
        </div>
        <div id="navbar" className="collapse navbar-collapse">
          <ul className="nav navbar-nav navbar-right">
            <li><Link to="/bootcamp">Training</Link></li>
            {/* <li><Link to="/blog">Blog</Link></li> */}
            <li><Link to="/contact">Contact</Link></li>
          </ul>
        </div> {/* /.nav-collapse */}
      </div>
    </nav>
  </header>
)

export default Header
